exports.components = {
  "component---src-components-templates-authors-listing-page-index-jsx": () => import("./../../../src/components/templates/AuthorsListingPage/index.jsx" /* webpackChunkName: "component---src-components-templates-authors-listing-page-index-jsx" */),
  "component---src-components-templates-country-profile-index-jsx": () => import("./../../../src/components/templates/CountryProfile/index.jsx" /* webpackChunkName: "component---src-components-templates-country-profile-index-jsx" */),
  "component---src-components-templates-database-index-jsx": () => import("./../../../src/components/templates/Database/index.jsx" /* webpackChunkName: "component---src-components-templates-database-index-jsx" */),
  "component---src-components-templates-events-index-jsx": () => import("./../../../src/components/templates/Events/index.jsx" /* webpackChunkName: "component---src-components-templates-events-index-jsx" */),
  "component---src-components-templates-expert-opinion-index-jsx": () => import("./../../../src/components/templates/ExpertOpinion/index.jsx" /* webpackChunkName: "component---src-components-templates-expert-opinion-index-jsx" */),
  "component---src-components-templates-features-index-jsx": () => import("./../../../src/components/templates/Features/index.jsx" /* webpackChunkName: "component---src-components-templates-features-index-jsx" */),
  "component---src-components-templates-grid-page-index-jsx": () => import("./../../../src/components/templates/GridPage/index.jsx" /* webpackChunkName: "component---src-components-templates-grid-page-index-jsx" */),
  "component---src-components-templates-node-basic-page-index-jsx": () => import("./../../../src/components/templates/NodeBasicPage/index.jsx" /* webpackChunkName: "component---src-components-templates-node-basic-page-index-jsx" */),
  "component---src-components-templates-partner-spotlight-index-jsx": () => import("./../../../src/components/templates/PartnerSpotlight/index.jsx" /* webpackChunkName: "component---src-components-templates-partner-spotlight-index-jsx" */),
  "component---src-components-templates-publications-index-jsx": () => import("./../../../src/components/templates/Publications/index.jsx" /* webpackChunkName: "component---src-components-templates-publications-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

